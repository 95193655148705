<template>
	<section v-if="data">
		<h2 class="accessible-hide">{{ sectionTitle }}</h2>
		<component
			:is="current_breakpoint.template"
			:section-title="sectionTitle"
			:data="data"
			:show-section-title="showSectionTitle"
			:full-height="fullHeight"
			:hide-title="hideTitle"
		/>
	</section>
</template>

<script>
import { get } from '@/helpers';
import baseComponent from '@/mixins/baseComponent';
import SlideshowBubblesSlider from './templates/Slideshow_BubblesSlider';
import SlideshowButtonLinks from './templates/Slideshow_ButtonLinks';
import SlideshowCardSlider from './templates/Slideshow_CardSlider';
import SlideshowCardList from './templates/Slideshow_CardList';
import SlideshowFeature from './templates/Slideshow_Feature';
import SlideshowHeroSlider from './templates/Slideshow_HeroSlider';
import SlideshowIntro from './templates/Slideshow_Intro';
import SlideshowLinkList from './templates/Slideshow_LinkList';
import SlideshowPanels from './templates/Slideshow_Panels';

export default {
	name: 'Slideshow',

	components: {
		SlideshowBubblesSlider,
		SlideshowButtonLinks,
		SlideshowCardSlider,
		SlideshowCardList,
		SlideshowFeature,
		SlideshowHeroSlider,
		SlideshowIntro,
		SlideshowLinkList,
		SlideshowPanels,
	},

	mixins: [baseComponent],

	props: {
		slideshowId: {
			type: Number,
			required: true,
		},
		showSectionTitle: {
			type: Boolean,
			default: true,
		},
		fullHeight: {
			type: Boolean,
		},
		hideTitle: {
			type: Boolean,
		},
	},

	methods: {
		get_data() {
			let request_options = {
				type: 'slideshow',
				count: this.count,
				start: this.start,
				slideshow_id: this.slideshowId,
				sport_id: this.sport_id,
			};

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			)
				.then(response => {
					this.original_data = response;
					this.setup_breakpoints();
				})
				.catch(error => {
					console.log(error);
				});
		},
	},

	watch: {
		$route() {
			this.get_data();
		},
	},

	created() {
		this.get_data();
	},
};
</script>
