<template>
	<div
		class="component c-slideshow c-slideshow--button-links position-relative"
		v-if="data && data.length"
	>
		<div class="section-header margin-b-24">
			<div class="section-header__title margin-0">
				{{ sectionTitle }}
			</div>
		</div>
		<div class="c-slideshow__details text-align-center">
			<div
				class="c-slideshow__description bp-768:font-size-18 bp-768:margin-b-12"
				v-html="data[0].teaser"
			></div>
			<ul
				class="c-slideshow__links reset-list align-items-center justify-content-center flex-wrap-wrap bp-768:display-flex"
				v-show="data[0].links.length"
			>
				<li
					class="c-slideshow__links-item margin-y-24 bp-768:padding-12 bp-768:margin-y-0"
					v-for="link in data[0].links"
					:key="link.id"
				>
					<navigation-link
						_class="button --on-light max-width-initial display-inline-flex width-auto padding-x-48 bp-768:padding-x-72"
						:url="link.link_url"
					>
						{{ link.link_text }}
					</navigation-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'SlideshowButtonLinks',

	components: {
		NavigationLink,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		sectionTitle: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.c-slideshow {
	&__description {
		margin: 0 auto;
		max-width: 760px;
	}
	&__links {
		margin: 0 auto;
		max-width: 980px;
	}
}
</style>
