<template>
	<div
		class="component c-slideshow c-slideshow--big-slider position-relative"
		v-if="data && data.length"
	>
		<swiper
			class="c-slideshow__slider swiper bp-1024:bg-color-black"
			ref="swiper_slider"
			:options="swiper_options"
		>
			<swiper-slide
				class="c-slideshow__item"
				v-for="slide in data"
				:key="slide.id"
			>
				<div class="c-slideshow__media position-relative height-100">
					<img
						class="c-slideshow__image display-block height-100 width-100 object-fit-cover swiper-lazy"
						:data-src="
							slide.primary_image.images.length
								? `${$root.proxy}${slide.primary_image.images[0].image}?width=1920&height=1080&mode=crop&format=jpg&quality=80`
								: '@/assets/default.jpg'
						"
						:alt="
							slide.primary_image.images.length
								? slide.primary_image.images[0].alt
								: null
						"
					/>
					<div
						class="c-slideshow__subtitle display-flex position-absolute bottom-0 left-0 width-100 bp-768:display-none"
						v-if="slide.subtitle"
					>
						<span
							class="c-slideshow__subtitle-text font-weight-24 bg-color-primary text-on-primary padding-y-8 padding-l-24 padding-r-48"
						>
							{{ slide.subtitle }}
						</span>
					</div>
					<div
						class="swiper-lazy-preloader swiper-lazy-preloader-white"
					></div>
				</div>
				<div
					class="c-slideshow__details-wrap bp-768:align-items-center bp-768:display-flex bp-768:height-100 bp-768:left-0 bp-768:position-absolute bp-768:top-0 bp-768:width-100"
				>
					<div
						class="c-slideshow__details padding-x-24 padding-t-12 padding-b-0 bp-1024:padding-x-48"
					>
						<div
							class="c-slideshow__subtitle display-none bp-768:display-flex bp-768:margin-l-n24 bp-1024:margin-l-n48"
							v-if="slide.subtitle"
						>
							<span
								class="c-slideshow__subtitle-text font-weight-24 bg-color-primary text-on-primary padding-y-8 padding-l-24 padding-r-48 bp-1024:padding-l-48 bp-1024:padding-r-96"
							>
								{{ slide.subtitle }}
							</span>
						</div>
						<div
							class="c-slideshow__title text-transform-uppercase font-weight-700 margin-b-12 bp-768:text-default-on-dark bp-768:margin-y-12 bp-768:margin-x-0"
						>
							{{ slide.title }}
						</div>
						<div>
							<navigation-link
								v-if="slide.links"
								:url="slide.links[0].link_url"
								_class="c-slideshow__link display-inline-flex align-items-center text-decoration-none bp-768:text-default-on-dark bp-1024:font-size-24"
							>
								<span>{{ slide.links[0].link_text }}</span>
								<arrow-right-circle-icon
									class="margin-l-24 flex-shrink-0"
									size="18"
								/>
							</navigation-link>
						</div>
					</div>
				</div>
			</swiper-slide>
			<div
				class="swiper-pagination swiper-pagination-bullets"
				:class="{ 'display-none': data.length < 2 }"
				slot="pagination"
			></div>
		</swiper>
		<button
			@click="toggle_slider"
			class="swiper-pause-play reset text-default-on-dark position-absolute z-index-100 cursor-pointer"
			v-if="data.length > 1"
		>
			<pause-circle-icon v-if="swiper_is_playing" size="40" />
			<play-circle-icon v-else size="40" />
		</button>
	</div>
</template>

<script>
import NavigationLink from '@/components/Common/NavigationLink';
import {
	PauseCircleIcon,
	PlayCircleIcon,
	ArrowRightCircleIcon,
} from 'vue-feather-icons';

export default {
	name: 'SlideshowHeroSlider',

	components: {
		PauseCircleIcon,
		PlayCircleIcon,
		ArrowRightCircleIcon,
		NavigationLink,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},

	data: () => ({
		swiper_options: {
			touchReleaseOnEdges: true,
			autoplay: {
				delay: 4000,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
			},
		},

		swiper_is_playing: true,
	}),

	computed: {
		swiper() {
			return this.$refs.swiper_slider.$swiper;
		},
	},

	methods: {
		toggle_slider() {
			if (this.swiper.autoplay.running) {
				this.swiper.autoplay.stop();
				this.swiper_is_playing = false;
			} else {
				this.swiper.autoplay.start();
				this.swiper_is_playing = true;
			}
		},
	},

	mounted() {
		this.swiper.on('slideChangeTransitionStart', swiper => {
			if (!this.swiper.autoplay.running) {
				this.swiper_is_playing = false;
			} else {
				this.swiper_is_playing = true;
			}
		});
	},
};
</script>

<style lang="scss" scoped>
.c-slideshow {
	border-bottom: 6px solid var(--color-primary);

	.swiper-pause-play {
		right: 6px;
		top: calc((100vw * 0.5625) - 46px);
	}

	&__subtitle {
		font-size: 18px;

		&-text {
			clip-path: polygon(0 0, 100% 0, calc(100% - 24px) 100%, 0 100%);
		}
	}

	&__title {
		font-size: 1.375rem;
		font-family: mislab-std, sans-serif;
	}

	@media screen and (min-width: 768px) {
		border: none;

		.swiper-pause-play {
			right: 28px;
			bottom: 28px;
			top: auto;
		}

		&__media {
			&::after {
				background-color: rgba(0, 0, 0, 0.6);
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		&__details {
			flex: 1 1 auto;
			min-width: 1px;
		}

		&__title {
			font-size: 1.875rem;
		}
	}

	@media screen and (min-width: 1024px) {
		&__item {
			height: calc(100vh - var(--full-header-height));
		}

		&__title {
			font-size: 6rem;
		}

		&__subtitle {
			font-size: 2.25rem;

			&-text {
				clip-path: polygon(0 0, 100% 0, calc(100% - 48px) 100%, 0 100%);
			}
		}

		&__link {
			font-size: 1.5rem;
			max-width: 750px;

			svg {
				width: 24px;
				height: 24px;
			}
		}
	}
}
</style>

<style lang="scss">
.c-slideshow--big-slider {
	.swiper-lazy {
		opacity: 0;
		transition: all 0.4s 0.15s;

		&.swiper-lazy-loaded {
			opacity: 1;
		}
	}

	.swiper {
		&-pagination {
			align-items: center;
			bottom: auto;
			display: flex;
			justify-content: center;
			left: auto;
			padding: 24px;
			position: relative;
			width: 100%;

			&-bullet {
				background-color: var(--color-default-theme-stroke);
				border-radius: 0;
				height: 6px;
				margin: 0 6px !important;
				opacity: 1;
				transition: all 0.25s;
				width: 50px;

				&-active {
					background-color: var(--color-primary);
				}
			}

			@media screen and (min-width: 768px) {
				bottom: 48px;
				justify-content: flex-start;
				left: 0;
				margin: 0 -12px;
				padding: 0 48px;
				position: absolute;

				&-bullet {
					background-color: var(--color-text-on-dark);
					margin: 0 12px !important;
					width: 100px;

					&-active {
						background-color: var(--color-primary);
					}
				}
			}
		}
	}
}
</style>
