<template functional>
	<div
		class="c-panel-links row bp-1024:flex-wrap-nowrap"
		:class="{ '--full-height': props.fullHeight }"
	>
		<div
			class="c-panel-links__item col-1-of-1 bg-color-black text-default-on-dark position-relative"
			v-for="item in props.items"
			:key="item.id"
		>
			<img
				class="c-panel-links__image object-fit-cover utility-position-cover"
				v-lazy="
					`${parent.$root.proxy}${item.primary_image.images[0].image}?width=1920&mode=crop&format=jpg&quality=80`
				"
				:alt="item.primary_image.images[0].alt"
			/>
			<div
				class="c-panel-links__item-inner utility-flex-center text-align-center position-relative z-index-2 width-100 height-100"
			>
				<div class="c-panel-links__details padding-x-16 padding-y-48">
					<div
						class="c-panel-links__title font-size-24 font-weight-700 margin-b-24 bp-768:font-size-36"
						v-if="!props.hideTitle"
					>
						{{ item.title }}
					</div>
					<div
						class="c-panel-links__teaser margin-b-24 bp-768:font-size-18"
						v-if="item.teaser"
						v-html="item.teaser"
					></div>

					<component
						_class="c-panel-links__link button --on-dark max-width-initial width-auto display-inline-flex padding-x-48"
						:url="item.links[0].link_url"
						:is="injections.components.NavigationLink"
					>
						{{ item.links[0].link_text }}
					</component>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'PanelLinks',

	inject: {
		components: {
			default: {
				NavigationLink,
			},
		},
	},

	props: {
		items: {
			type: Array,
			required: true,
		},
		fullHeight: {
			type: Boolean,
		},
		hideTitle: {
			type: Boolean,
		},
	},
};
</script>

<style lang="scss">
.c-panel-links {
	&__list {
		margin: -2px 0;
	}

	&__item {
		min-height: 23.4375rem;
		margin: 2px 0;

		&-inner {
			background-color: rgba(0, 0, 0, 0.65);
		}
	}

	@media screen and (min-width: 1024px) {
		&.--full-height {
			.c-panel-links__item {
				height: calc(100vh - var(--full-header-height));
			}
		}

		&__list {
			margin: 0;
		}

		&__item {
			height: auto;
			margin: 0;
			min-height: 28.125vw;
			flex: 1 0 0px;
			min-width: 1px;
			transition: flex-basis 0.25s;

			+ .c-panel-links__item {
				border-left: 2px solid white;
			}

			&:hover {
				flex-basis: 5%;
			}
		}
	}
}
</style>
