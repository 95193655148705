<template>
	<div
		class="c-contentstream--packery row max-width-grid padding-y-24 bp-1024:padding-y-72 position-relative z-index-2"
	>
		<h2
			class="c-contentstream__title font-size-24 font-weight-700 padding-x-12 bp-1024:font-size-36 bp-1440:padding-x-0 margin-x-0 margin-t-0 margin-b-12 text-on-primary text-align-center width-100"
		>
			{{ sectionTitle }}
		</h2>
		<ul
			class="c-contentstream__stream reset-list overflow-hidden position-relative width-100"
			v-packery="{
				itemSelector: '.c-contentstream__item',
				percentPosition: true,
			}"
		>
			<li
				class="c-contentstream__item padding-12 width-100 bp-540:width-50 bp-1024:width-33"
				:class="`c-contentstream__item--${item.type}`"
				v-for="(item, index) in data"
				:key="index"
				v-packery-item
			>
				<div
					class="c-contentstream__item-inner bg-color-white padding-24"
				>
					<twitter-card
						:data="item.data"
						v-if="item.type === 'tweet'"
					/>
					<facebook-card
						:data="item.data"
						v-if="item.type === 'facebook'"
					/>
					<instagram-card
						:data="item.data"
						v-if="item.type === 'instagram'"
					/>
					<youtube-card
						:data="item.data"
						v-if="item.type === 'youtube'"
					/>
				</div>
			</li>
		</ul>
		<div
			class="c-contentstream__load-more bp-1024:margin-t-12 text-align-center width-100"
			v-if="more_to_load"
		>
			<button
				class="c-contentstream__load-more-btn button --on-dark display-inline-block reset"
				@click="load_more()"
			>
				{{ is_loading_more ? 'Loading...' : 'Load More' }}
			</button>
		</div>
	</div>
</template>

<script>
import { packeryEvents } from 'vue-packery-plugin';
import TwitterCard from '../cards/TwitterCard';
import FacebookCard from '../cards/FacebookCard';
import InstagramCard from '../cards/InstagramCard';
import YoutubeCard from '../cards/YoutubeCard';

export default {
	name: 'ContentStreamPackery',

	components: {
		TwitterCard,
		FacebookCard,
		InstagramCard,
		YoutubeCard,
	},

	props: {
		data: {
			required: true,
			type: Array,
			default: () => [],
		},
		sectionTitle: {
			type: String,
		},
		is_loading_more: {
			required: true,
			type: Boolean,
			default: false,
		},
		more_to_load: {
			required: true,
			type: Boolean,
			default: true,
		},
	},

	methods: {
		load_more() {
			this.$emit('load-more', 'value');
		},
	},

	mounted() {
		this.$Lazyload.$on('loaded', () => {
			packeryEvents.$emit(
				'layout',
				document.querySelector('.c-contentstream__stream'),
			);
		});
	},
};
</script>

<style lang="scss">
.c-contentstream--packery {
	.c-contentstream {
		//for naming
		&__title{
			font-family: mislab-std, sans-serif;
		}
		&__user {
			border-bottom: 1px dotted #e3e3e3;

			.c-contentstream__item--instagram & {
				border-bottom: none;
			}

			&-image {
				border-radius: 50%;
				height: 48px;
				width: 48px;
			}
			&-screen {
				&:hover,
				&:focus {
					color: var(--color-primary);
				}
			}
		}

		&__media-image,
		&__media-iframe {
			border-radius: 12px;
		}

		&__text {
			a {
				color: var(--color-primary);
			}
		}

		&__actions {
			&-url {
				transform: translateY(0);

				&:hover,
				&:focus {
					color: var(--color-primary);
					transform: translateY(-3px);
				}
			}
		}
	}
}
</style>
