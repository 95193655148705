<template>
	<section
		class="component c-contentstream position-relative"
		v-if="data && data.length"
	>
		<component
			:is="current_breakpoint.template"
			:section-title="sectionTitle"
			:data="data"
			:is_loading_more="is_loading_more"
			:more_to_load="more_to_load"
			@load-more="load_more()"
		/>
	</section>
</template>

<script>
import { get } from '@/helpers';
import baseComponent from '@/mixins/baseComponent';
import ContentStreamPackery from './templates/ContentStream_Packery';

export default {
	name: 'ContentStream',

	mixins: [baseComponent],

	components: {
		ContentStreamPackery,
	},

	data: () => ({
		is_loading_more: false,
		more_to_load: true,
		types: [],
	}),

	methods: {
		get_data() {
			let request_options = {
				type: 'content-stream',
				count: this.count,
				start: this.start,
				name: 'all',
			};

			if (this.division_id != 0) {
				request_options.division_id = this.division_id;
			} else if (this.sport_id != 0) {
				request_options.sport_id = this.sport_id;
			} else {
				request_options.sport_id = 0;
			}

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			)
				.then(response => {
					this.original_data = response.stream;
					this.types = response.types;
					this.setup_breakpoints();
				})
				.catch(error => {
					console.log(error);
				});
		},

		load_more() {
			this.is_loading_more = true;

			let request_options = {
				type: 'content-stream',
				count: this.count,
				start: this.original_data.length,
				name: 'all',
			};

			if (this.division_id != 0) {
				request_options.division_id = this.division_id;
			} else if (this.sport_id != 0) {
				request_options.sport_id = this.sport_id;
			} else {
				request_options.sport_id = 0;
			}

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			)
				.then(response => {
					this.original_data = [
						...this.original_data,
						...response.stream,
					];
					this.is_loading_more = false;
					if (!response.stream.length) {
						this.more_to_load = false;
					}
				})
				.catch(error => {
					this.is_loading_more = false;
					this.more_to_load = false;
					console.log(error);
				});
		},
	},

	watch: {
		$route() {
			this.get_data();
		},
	},

	created() {
		this.get_data();
	},
};
</script>

<style lang="scss" scoped>
.c-contentstream {
	background: #0961af;

	&::before {
		content: '';
		background: var(--color-primary);
		clip-path: polygon(0 50%, 0 0, 100% 50%, 100% 100%);
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	&__title::after {
		content: '';
		background: var(--color-secondary);
		display: block;
		height: 6px;
		margin: 12px auto 0;
		width: 100px;
	}
}
</style>
