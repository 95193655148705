<template functional>
	<div>
		<div
			class="c-contentstream__user display-flex align-items-center padding-b-24"
		>
			<img
				src="@/assets/main_logo.svg"
				alt="NCAA"
				class="c-contentstream__user-image"
			/>
			<a
				href="https://www.youtube.com/user/ncaa"
				class="c-contentstream__user-screen font-weight-500 margin-l-12 margin-r-auto text-default text-decoration-none hover:text-decoration-underline transition"
				rel="noopener"
				target="_blank"
				>@NCAA</a
			>
			<span
				class="c-contentstream__user-icon sidearm-icon sf-youtube-play font-size-24 margin-l-12 text-social-youtube"
			></span>
		</div>
		<div class="c-contentstream__body padding-y-24">
			<div
				class="c-contentstream__media position-relative aspect-ratio-16-9 text-align-center"
			>
				<lazy-component>
					<iframe
						class="c-contentstream__media-iframe position-absolute top-0 left-0 height-100 width-100"
						:src="`https://www.youtube.com/embed/${props.data.id}`"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</lazy-component>
			</div>
			<div class="c-contentstream__text font-size-16 margin-t-24">
				{{ props.data.title }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'YoutubeCard',

	props: {
		data: {
			required: true,
			type: Object,
		},
	},
};
</script>
