<template functional>
	<div>
		<div
			class="c-contentstream__user display-flex align-items-center padding-b-24"
		>
			<img
				v-lazy="props.data.user.image"
				:alt="props.data.user.name"
				class="c-contentstream__user-image"
			/>
			<a
				:href="`https://twitter.com/${props.data.user.screen_name}`"
				class="c-contentstream__user-screen font-weight-500 margin-l-12 margin-r-auto text-default text-decoration-none hover:text-decoration-underline transition"
				rel="noopener"
				target="_blank"
				>{{ '@' + props.data.user.screen_name }}</a
			>
			<span
				class="c-contentstream__user-icon sidearm-icon sf-twitter font-size-24 margin-l-12 text-social-twitter"
			></span>
		</div>
		<div class="c-contentstream__body padding-y-24">
			<div
				class="c-contentstream__media margin-b-24 text-align-center"
				v-if="props.data.media"
			>
				<img
					v-lazy="props.data.media"
					alt
					class="c-contentstream__media-image"
				/>
			</div>
			<div
				class="c-contentstream__text font-size-16"
				v-html="props.data.text"
			></div>
		</div>
		<ul
			class="c-contentstream__actions reset-list display-flex align-items-center flex-wrap-wrap"
		>
			<li class="c-contentstream__actions-item margin-r-12">
				<a
					:aria-label="`Reply to tweet: ${props.data.text}`"
					:href="
						`https://twitter.com/intent/tweet?in_reply_to=${props.data.id}`
					"
					class="c-contentstream__actions-url display-inline-block text-brand text-decoration-none hover:text-decoration-underline transition"
					target="_blank"
				>
					<span
						aria-hidden="true"
						class="sidearm-icon sf-twitter-reply font-size-24"
					></span>
				</a>
			</li>
			<li class="c-contentstream__actions-item margin-r-12">
				<a
					:aria-label="`Retweet tweet: ${props.data.text}`"
					:href="
						`https://twitter.com/intent/retweet?tweet_id=${props.data.id}`
					"
					class="c-contentstream__actions-url display-inline-block text-brand text-decoration-none hover:text-decoration-underline transition"
					target="_blank"
				>
					<span
						aria-hidden="true"
						class="sidearm-icon sf-twitter-retweet font-size-24"
					></span>
				</a>
			</li>
			<li class="c-contentstream__actions-item margin-r-12">
				<a
					:aria-label="`Like tweet: ${props.data.text}`"
					:href="
						`https://twitter.com/intent/favorite/?tweet_id=${props.data.id}`
					"
					class="c-contentstream__actions-url display-inline-block text-brand text-decoration-none hover:text-decoration-underline transition"
					target="_blank"
				>
					<span
						aria-hidden="true"
						class="sidearm-icon sf-twitter-like font-size-24"
					></span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'TwitterCard',

	props: {
		data: {
			required: true,
			type: Object,
		},
	},
};
</script>
