<template>
	<div
		class="component c-slideshow c-slideshow--intro position-relative"
		v-if="data && data.length"
	>
		<div class="text-align-center">
			<div
				v-if="sectionTitle && !hideTitle"
				class="section-header margin-b-24 bp-768:margin-b-48"
			>
				<div class="section-header__title margin-0">
					{{ sectionTitle }}
				</div>
			</div>
			<div
				v-if="
					data[0].primary_image && data[0].primary_image.images.length
				"
				class="c-slideshow__media margin-b-24 bp-768:margin-b-48 bp-1024:margin-b-72"
			>
				<img
					class="display-inline-block"
					v-lazy="$root.proxy + data[0].primary_image.images[0].image"
					:alt="data[0].primary_image.images[0].alt"
				/>
			</div>
			<div
				class="c-slideshow__description margin-x-auto bp-768:font-size-18"
				v-html="data[0].teaser"
			></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SlideshowIntro',

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		sectionTitle: {
			type: String,
		},
		hideTitle: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.c-slideshow {
	&__description {
		max-width: 1176px;
	}
}
</style>
