<template functional>
	<div class="c-card">
		<div class="c-card__media aspect-ratio-16-9 position-relative">
			<img
				class="utility-position-cover object-fit-cover lazy"
				v-lazy="
					`${parent.$root.proxy}${props._image.image}?width=456&height=257&mode=crop&format=jpg&quality=80`
				"
				:alt="props._image ? props._image.alt : ''"
				v-if="props._image"
			/>
			<img
				class="utility-position-cover object-fit-cover lazy"
				src="@/assets/default.jpg"
				v-else
			/>
		</div>
		<div
			class="c-card__details padding-12 bp-1024:padding-x-24 bp-1024:padding-t-24 bp-1024:padding-b-12"
		>
			<div
				class="c-card__title font-weight-700 font-size-18 margin-b-12"
				v-if="props._title"
			>
				<component
					_class="display-block text-decoration-none hover:text-decoration-underline"
					:is="injections.components.NavigationLink"
					v-if="props._url"
					:url="props._url"
				>
					{{ props._title }}
				</component>
				<span v-else>{{ props._title }}</span>
			</div>
			<div
				class="c-card__description"
				v-if="props._description"
				v-html="props._description"
			></div>
			<div class="c-card__links" v-if="props._links">
				<ul class="c-card__links-list reset-list">
					<li
						class="c-card__links-item border-b border-style-dashed"
						v-for="item in props._links.slice(1)"
						:key="item.id"
					>
						<component
							:is="injections.components.NavigationLink"
							:url="item.link_url"
							_class="c-card__links-link padding-y-12 display-flex align-items-center text-brand text-decoration-none transition"
						>
							{{ item.link_text }}
							<component
								class="margin-l-12 text-brand transition transition-timing-function-ease"
								:is="injections.components.LinkIcon"
								size="18"
							></component>
						</component>
					</li>
					<li class="c-card__links-item">
						<component
							:is="injections.components.NavigationLink"
							:url="props._links[0].link_url"
							_class="c-card__links-link --primary-link padding-y-12 display-flex align-items-center font-weight-700 text-default text-decoration-none transition"
						>
							{{ props._links[0].link_text }}
							<component
								class="margin-l-12 text-brand transition transition-timing-function-ease"
								:is="injections.components.ArrowRightCircleIcon"
								size="24"
							></component>
						</component>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { ArrowRightCircleIcon, LinkIcon } from 'vue-feather-icons';
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'Card',

	inject: {
		components: {
			default: {
				NavigationLink,
				ArrowRightCircleIcon,
				LinkIcon,
			},
		},
	},

	props: {
		_image: {
			type: Object,
		},
		_title: {
			type: String,
		},
		_description: {
			type: String,
		},
		_links: {
			type: Array,
		},
		_url: {
			type: String,
		},
	},
};
</script>

<style lang="scss">
.c-card {
	&__links-link {
		&:hover,
		&:focus {
			color: var(--color-primary);

			> svg {
				transform: translateX(6px);
			}
		}
	}
}
</style>
