<template>
	<div
		class="component c-slideshow c-slideshow--card-list position-relative"
		v-if="data && data.length"
	>
		<div class="section-header margin-b-24 bp-768:margin-b-48">
			<div class="section-header__title margin-0">
				{{ sectionTitle }}
			</div>
		</div>
		<ul class="c-slideshow__list reset-list row margin-n12">
			<li
				class="c-slideshow__item col-1-of-1 padding-12
					bp-768:col-1-of-2
					bp-1024:col-1-of-3"
				v-for="item in data"
				:key="item.id"
			>
				<card
					:_image="item.primary_image.images[0]"
					:_title="item.title"
					:_description="item.teaser"
					:_links="item.links"
				/>
			</li>
		</ul>
	</div>
</template>

<script>
import Card from '@/components/Common/Card.vue';

export default {
	name: 'SlideshowCardList',

	components: {
		Card,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		sectionTitle: {
			type: String,
		},
	},
};
</script>
