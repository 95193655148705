<template>
	<div class="component c-slideshow c-slideshow--link-list">
		<ul
			class="c-slideshow__list row reset-list margin-n6
				bp-768:margin-n12"
		>
			<li
				class="c-slideshow__item col-1-of-1 padding-6
					bp-768:col-1-of-2 bp-768:padding-12
					bp-1024:col-1-of-3"
				v-for="(item, index) in data"
				:key="index"
			>
				<div
					class="c-slideshow__item-inner bg-color-white border padding-24"
				>
					<div
						class="c-slideshow__title border-b font-size-18 font-weight-500 padding-y-16 text-brand"
					>
						{{ item.title }}
					</div>
					<ul class="c-slideshow__link-list reset-list">
						<li
							class="c-slideshow__link-list-item border-b padding-y-12"
							v-for="(link, index) in item.links"
							:key="index"
						>
							<navigation-link
								_class="c-slideshow__link-list-link align-items-center display-flex text-decoration-none hover:text-decoration-underline"
								:url="link.link_url"
								>{{ link.link_text }}</navigation-link
							>
						</li>
					</ul>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'SlideshowLinkList',

	components: {
		NavigationLink,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},
};
</script>

<style lang="scss" scoped>
.c-slideshow {
	//for naming
	&__link-list-item {
		border-bottom-style: dotted;
	}
}
</style>

<style lang="scss">
.c-slideshow--link-list {
	.c-slideshow {
		//for naming
		&__link-list-link {
			&::before {
				content: '';
				background: var(--color-primary);
				border-radius: 50%;
				flex: 0 0 auto;
				margin-right: 24px;
				height: 8px;
				width: 8px;
			}
		}
		&__title{
			font-family: mislab-std, sans-serif;
			font-weight:700;
		}
	}
}
</style>
