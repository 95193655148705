<template functional>
	<div>
		<div
			class="c-contentstream__user display-flex align-items-center padding-b-24"
		>
			<img
				v-lazy="props.data.page.picture"
				:alt="props.data.page.name"
				class="c-contentstream__user-image"
			/>
			<a
				:href="props.data.page.link"
				class="c-contentstream__user-screen font-weight-500 margin-l-12 margin-r-auto text-default text-decoration-none hover:text-decoration-underline transition"
				rel="noopener"
				target="_blank"
				>{{ '@' + props.data.page.username }}</a
			>
			<span
				class="c-contentstream__user-icon sidearm-icon sf-facebook font-size-24 margin-l-12 text-social-facebook"
			></span>
		</div>
		<div class="c-contentstream__body padding-t-24">
			<div
				class="c-contentstream__media margin-b-24 text-align-center"
				v-if="props.data.full_picture"
			>
				<img
					v-lazy="props.data.full_picture"
					alt
					class="c-contentstream__media-image"
				/>
			</div>
			<div
				class="c-contentstream__text font-size-16"
				v-html="props.data.message"
			></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FacebookCard',

	props: {
		data: {
			required: true,
			type: Object,
		},
	},
};
</script>
