<template>
	<div
		class="component c-slideshow c-slideshow--panels position-relative"
		v-if="data && data.length"
	>
		<div
			class="section-header margin-b-24 bp-768:margin-b-48 bp-1024:margin-b-72"
			v-if="showSectionTitle"
		>
			<div class="section-header__title margin-0">
				{{ sectionTitle }}
			</div>
		</div>
		<panel-links
			:items="data"
			:full-height="fullHeight"
			:hide-title="hideTitle"
		/>
	</div>
</template>

<script>
import PanelLinks from '@/components/Common/PanelLinks';

export default {
	name: 'Priorities',

	components: {
		PanelLinks,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		sectionTitle: {
			type: String,
		},
		showSectionTitle: {
			type: Boolean,
			default: true,
		},
		fullHeight: {
			type: Boolean,
		},
		hideTitle: {
			type: Boolean,
		},
	},
};
</script>

<style lang="scss" scoped>
.c-slideshow {
	padding-bottom: 2px;
	.c-panel-links__title{
		font-family: mislab-std, sans-serif;
	}
}
</style>
