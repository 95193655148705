<template>
	<div
		class="component c-slideshow c-slideshow--feature position-relative"
		v-if="data && data.length"
	>
		<div
			class="c-slideshow__media overflow-hidden position-relative aspect-ratio-16-9"
		>
			<picture
				class="c-slideshow__picture position-absolute top-0 left-0 overflow-hidden"
				v-if="slide.primary_image.images.length"
			>
				<source
					media="(min-width: 1024px)"
					:srcset="
						`${$root.proxy}${slide.primary_image.images[0].image}?width=1920&height=1080&mode=crop&format=jpg&quality=80`
					"
				/>
				<source
					media="(min-width: 641px)"
					:srcset="
						`${$root.proxy}${slide.primary_image.images[0].image}?width=1024&height=576&mode=crop&format=jpg&quality=80`
					"
				/>
				<source
					media="(min-width: 0px)"
					:srcset="
						`${$root.proxy}${slide.primary_image.images[0].image}?width=640&height=360&mode=crop&format=jpg&quality=80`
					"
				/>
				<img
					class="width-100"
					v-lazy="
						`${$root.proxy}${slide.primary_image.images[0].image}?width=936&height=527&mode=crop&format=jpg&quality=80`
					"
					:alt="slide.primary_image.images[0].alt"
				/>
			</picture>
			<img
				class="width-100 position-absolute top-0 left-0"
				src="@/assets/default.jpg"
				v-else
			/>
		</div>
		<div class="c-slideshow__details padding-t-24">
			<div
				class="c-slideshow__title font-size-18 bp-768:font-size-24 bp-1024:font-size-36 font-weight-700 margin-t-0 margin-b-12 padding-l-16"
			>
				{{ data[0].title }}
			</div>
			<div
				class="c-slideshow__subtitle text-muted font-size-16 padding-l-24"
			>
				{{ data[0].subtitle }}
			</div>
			<ul
				class="c-slideshow__links display-flex align-items-center flex-wrap-wrap padding-l-24 reset-list"
				v-if="data[0].links && data[0].links.length"
			>
				<li
					class="c-slideshow__item margin-12 margin-l-0"
					v-for="(link, index) in data[0].links"
					:key="index"
				>
					<navigation-link
						_class="c-slideshow__url display-flex align-items-center font-size-16 font-weight-700 text-default text-decoration-none transition"
						:url="link.link_url"
					>
						{{ link.link_text }}
						<arrow-right-circle-icon
							class="c-slideshow__icon font-weight-400 margin-l-12 text-brand transition"
						></arrow-right-circle-icon>
					</navigation-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { ArrowRightCircleIcon } from 'vue-feather-icons';
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'SlideshowFeature',

	components: {
		NavigationLink,
		ArrowRightCircleIcon,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},

	computed: {
		slide() {
			return this.data[0];
		},
	},
};
</script>

<style lang="scss" scoped>
.c-slideshow {
	&__title {
		border-left: 6px solid var(--color-primary);
		font-family: mislab-std, sans-serif;
	}
}
</style>

<style lang="scss">
.c-slideshow--feature {
	.c-slideshow {
		&__url {
			&:hover,
			&:focus {
				color: var(--color-primary);

				.c-slideshow__icon {
					transform: translate(6px);
				}
			}
		}
	}
}
</style>
