<template functional>
	<div>
		<div class="c-contentstream__media text-align-center">
			<img
				v-lazy="props.data.images.standard_resolution.url"
				:alt="props.data.caption ? props.data.caption.text : undefined"
				class="c-contentstream__media-image"
			/>
		</div>
		<div
			class="c-contentstream__user display-flex align-items-center margin-t-24"
		>
			<img
				v-lazy="props.data.user.profile_picture"
				:alt="props.data.user.full_name"
				class="c-contentstream__user-image"
			/>
			<a
				:href="`https://instagram.com/${props.data.user.username}`"
				class="c-contentstream__user-screen font-weight-500 margin-l-12 margin-r-auto text-default text-decoration-none hover:text-decoration-underline transition"
				rel="noopener"
				target="_blank"
				>{{ '@' + props.data.user.username }}</a
			>
			<span
				class="c-contentstream__user-icon sidearm-icon sf-instagram font-size-24 margin-l-12 text-social-instagram"
			></span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InstagramCard',

	props: {
		data: {
			required: true,
			type: Object,
		},
	},
};
</script>
