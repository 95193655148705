<template>
	<div class="c-card-slider">
		<swiper
			class="c-card-slider__slider swiper"
			ref="swiper_slider"
			:options="swiper_options"
		>
			<swiper-slide
				class="c-card-slider__item height-auto"
				v-for="slide in slides"
				:key="slide.id"
			>
				<card
					:_image="slide.primary_image.images[0]"
					:_title="slide.title"
					:_description="slide.teaser"
					:_links="slide.links"
				/>
			</swiper-slide>
			<div
				class="swiper-button-prev"
				slot="button-prev"
				v-if="slides.length > 1"
			>
				<arrow-left-icon size="24"></arrow-left-icon>
			</div>
			<div
				class="swiper-button-next"
				slot="button-next"
				v-if="slides.length > 1"
			>
				<arrow-right-icon size="24"></arrow-right-icon>
			</div>
		</swiper>
	</div>
</template>

<script>
import Card from '@/components/Common/Card';
import { ArrowRightIcon, ArrowLeftIcon } from 'vue-feather-icons';
export default {
	name: 'CardSlider',

	components: {
		Card,
		ArrowRightIcon,
		ArrowLeftIcon,
	},

	props: {
		slides: {
			type: Array,
			required: true,
		},
	},

	data: () => ({
		swiper_options: {
			spaceBetween: 24,
			touchReleaseOnEdges: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
		},
	}),
};
</script>

<style lang="scss">
.c-card-slider {
	.swiper-container {
		padding-bottom: 48px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		bottom: 0;
		top: auto;
		left: calc(50% - 48px);
		height: 48px;
		width: 48px;

		&::after {
			content: none;
		}
	}

	.swiper-button-next {
		margin-left: 48px;
	}
}
</style>
