<template>
	<div
		class="component c-slideshow c-slideshow--bubbles-slider position-relative"
		v-if="data && data.length"
	>
		<div class="section-header display-none margin-b-24 bp-768:margin-b-48">
			<div class="section-header__title margin-0">
				{{ sectionTitle }}
			</div>
		</div>
		<swiper
			class="c-slideshow__slider swiper"
			ref="swiper_slider"
			:options="swiper_options"
		>
			<swiper-slide
				class="c-slideshow__item text-align-center height-auto"
				v-for="slide in data"
				:key="slide.id"
			>
				<navigation-link
					:url="slide.primary_link ? slide.primary_link : '#'"
					_class="c-slideshow__link display-block text-decoration-none height-100"
					:_aria-label="slide.title"
				>
					<div class="c-slideshow__media margin-b-24">
						<img
							class="c-slideshow__media-image border-radius-rounded object-fit-cover"
							v-lazy="slide_image(slide)"
							:alt="
								slide.primary_image.images.length
									? slide.primary_image.images[0].alt
									: ''
							"
							width="165"
							height="165"
						/>
					</div>
					<div class="c-slideshow__details">
						<div
							class="text-default font-weight-700 bp-768:font-size-18"
						>
							{{ slide.title }}
						</div>
					</div>
				</navigation-link>
			</swiper-slide>
			<div class="swiper-button-prev" slot="button-prev">
				<arrow-left-icon size="24"></arrow-left-icon>
			</div>
			<div class="swiper-button-next" slot="button-next">
				<arrow-right-icon size="24"></arrow-right-icon>
			</div>
		</swiper>
	</div>
</template>

<script>
import { ArrowRightIcon, ArrowLeftIcon } from 'vue-feather-icons';
import NavigationLink from '@/components/Common/NavigationLink';
import default_image from '@/assets/default.jpg';

export default {
	name: 'FeaturedLinks',

	components: {
		NavigationLink,
		ArrowRightIcon,
		ArrowLeftIcon,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		sectionTitle: {
			type: String,
		},
	},

	data: () => ({
		swiper_options: {
			spaceBetween: 24,
			touchReleaseOnEdges: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
		},
	}),

	methods: {
		slide_image: function(slide) {
			if (slide.primary_image.images.length) {
				return `${this.$root.proxy}${slide.primary_image.images[0].image}?width=250&height=250&mode=crop&format=jpg&quality=80`;
			}
			return default_image;
		},
	},
};
</script>

<style lang="scss" scoped>
.c-slideshow {
	&__link {
		&:hover,
		&:focus {
			.c-slideshow {
				&__media-image {
					box-shadow: 0 0 0 6px var(--color-primary),
						0 0 0 6px #d3d3d3;
				}
			}
		}
	}

	&__media-image {
		box-shadow: 0 0 0 0 var(--color-primary),
			0 0 0 6px var(--color-default-theme-stroke);
		margin: 6px;
		transition: all 0.25s;
	}
}
</style>
<style lang="scss">
.c-slideshow--bubbles-slider {
	.swiper-container {
		padding-top: 54px;
		margin-top: -54px;

		@media screen and (min-width: 768px) {
			padding-top: 96px;
			margin-top: -96px;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		height: 48px;
		left: 0;
		margin: 0;
		top: 0;
		width: 48px;

		&::after {
			content: none;
		}

		@media screen and (min-width: 1280px) {
			&.swiper-button-disabled {
				display: none;
			}
		}
	}

	.swiper-button-next {
		right: 0;
		left: auto;
	}
}
</style>
