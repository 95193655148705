<template>
	<div
		class="component c-slideshow c-slideshow--card-slider position-relative"
		v-if="data && data.length"
	>
		<div class="section-header display-none margin-b-24 bp-768:margin-b-48">
			<div class="section-header__title margin-0">
				{{ sectionTitle }}
			</div>
		</div>
		<card-slider :slides="data" />
	</div>
</template>

<script>
import CardSlider from '@/components/Common/CardSlider.vue';

export default {
	name: 'SlideshowCardSlider',

	components: {
		CardSlider,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		sectionTitle: {
			type: String,
		},
	},
};
</script>
